import languagesList from '@/locales/languages.json';
import { useRoute } from 'vue-router';
// Interface for Language Service (TypeScript-like documentation)
/**
 * @typedef {Object} ILanguageService
 * @property {function(Array): string} getActiveLanguage
 * @property {function(Array): Array} processLanguages
 * @property {function(string, Array): string} determineUserLanguage
 * @property {function(string, Array): boolean} isLanguageAvailable
 */

// Factory function to create language service instances
export const languageSetup = () => {
  const route = useRoute();
  /**
   * Gets the active language from the available languages
   * @param {Array} languages - Array of available languages
   * @returns {string|undefined} Active language code
   */
  const getActiveLanguage = (languages) => {
    return languages.find((item) => item.active)?.language;
  };

  /**
   * Processes languages array to add labels
   * @param {Array} languages - Array of languages to process
   * @returns {Array} Processed languages with labels
   */
  const processLanguages = (languages) => {
    return languages.map((item) => ({
      ...item,
      label: languagesList[item.language],
    }));
  };

  /**
   * Checks if a language is available in the provided languages array
   * @param {string} language - Language code to check
   * @param {Array} availableLanguages - Array of available languages
   * @returns {boolean} Whether the language is available
   */
  const isLanguageAvailable = (language, availableLanguages) => {
    return availableLanguages?.some((lang) => lang.language === language);
  };

  /**
   * Determines the user's language based on various factors
   * @param {string} hotelId - Hotel ID for storage key
   * @param {Array} availableLanguages - Array of available languages
   * @returns {string} Determined language code
   */
  const determineUserLanguage = (hotelId, availableLanguages) => {
    // Check for previously selected language
    const selectedUserLanguage = sessionStorage.getItem('language' + hotelId);

    if (
      selectedUserLanguage &&
      isLanguageAvailable(selectedUserLanguage, availableLanguages)
    ) {
      return selectedUserLanguage;
    }

    if (route.query.l) {
      if (isLanguageAvailable(route.query.l, availableLanguages)) {
        return route.query.l;
      }
    }

    // Fall back to browser language
    let browserLanguage = navigator.language || navigator.userLanguage;
    if (browserLanguage.includes('-')) {
      browserLanguage = browserLanguage.split('-')[0];
    }

    if (isLanguageAvailable(browserLanguage, availableLanguages)) {
      return browserLanguage;
    }

    // If no match found, return the active language or first available
    return (
      getActiveLanguage(availableLanguages) || availableLanguages[0]?.language
    );
  };

  return {
    getActiveLanguage,
    processLanguages,
    determineUserLanguage,
    isLanguageAvailable,
  };
};
