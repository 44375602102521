import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { setLocale } from '@/locales/i18n';
import { useAppConfigStore } from '@stores/appConfig.store';
import { useTranslationCache } from '@stores/translationCache.store';
import { useBookingQueryStore } from '@stores/bookingQuery.store';
import { useMenus } from '@stores/menus.store';
import { usePixelsService } from '@/services/pixels';
import { useGoogleAnalytics } from '@/services/analytics';
import { useAuthentication } from '@/services/auth';
import { setupService } from '@/services/setup';
import { useI18n } from 'vue-i18n';
import { useFavicon } from '@/composables/useFavicon';
import { useCookiesStore } from '@/stores/cookies.store';

export function useAppInitialization() {
  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n();
  const auth = useAuthentication();
  const setupServiceInstance = setupService();
  const { fetchPixelsConfig } = usePixelsService();
  const { initializeAnalytics } = useGoogleAnalytics();
  const { setFaviconByUrl } = useFavicon();

  const appConfigStore = useAppConfigStore();
  const bookingQueryStore = useBookingQueryStore();
  const translationCache = useTranslationCache();
  const menusStore = useMenus();
  const cookiesStore = useCookiesStore();
  const { userCookies } = storeToRefs(cookiesStore);

  const { setLanguage, setApplicationSetup } = appConfigStore;
  const { setupLoaded, applicationSetup } = storeToRefs(appConfigStore);
  const { getMenu } = menusStore;
  const { setInitialQuery } = bookingQueryStore;

  const informativeModalTitle = ref('This is awkward...');
  const informativeModalDescription = ref(
    'Oops, something went wrong.<br>We ran into a technical issue.<br><br>Just hit the refresh icon to try again.',
  );
  const informativeModalButtonText = ref('');

  const initializeApp = async (languageOverride = null) => {
    try {
      await auth.login();
      const [setupData, pixelsConfig] = await Promise.all([
        setupServiceInstance.getSetup(languageOverride ?? null),
        fetchPixelsConfig(),
      ]);

      // Make sure hotel_id is present in setupData
      if (!setupData.hotel_id) {
        console.error('Hotel ID is missing in setup data:', setupData);
      }

      // First set the application setup data
      await setApplicationSetup(setupData);

      // Set the favicon based on the hotel ID
      if (setupData.hotel_favicon) {
        setFaviconByUrl(setupData.hotel_favicon);
      }

      // Then handle pixels config
      if (pixelsConfig?.tag_manager?.ga_codes) {
        const gaCodes = pixelsConfig.tag_manager.ga_codes;
        gaCodes.gtm_container_id = pixelsConfig.tag_manager.tag_manager_id;

        // Store the config globally so we can access it later
        window._gaConfig = gaCodes;

        const cookieConsent = userCookies.value;

        if (!cookieConsent?.analyticsCookies) {
          const { disableTracking } = useGoogleAnalytics();
          const trackingId = pixelsConfig.tag_manager.ga_codes.bg_ga_code;
          disableTracking(trackingId);
        } else {
          console.log('Initializing Google Analytics');
          initializeAnalytics(pixelsConfig.tag_manager.ga_codes);
        }
      }

      setupServiceInstance.applyCssOverride(
        setupData.hotel_id,
        setupData.hotel_template_css,
      );

      if (
        Object.keys(applicationSetup.value) &&
        Object.keys(applicationSetup.value).some(
          (key) => !['apiKey', 'channelKey'].includes(key),
        )
      ) {
        setLanguage(applicationSetup.value.language);
        await setLocale(applicationSetup.value.language);
        getMenu();

        setInitialQuery(applicationSetup.value);

        await translationCache.fetchAndUpdateTranslations();

        informativeModalTitle.value = t('general.thisIsAwkward');
        informativeModalDescription.value = t('somethingWentWrong');
        informativeModalButtonText.value = t('general.closeButtonText');

        setupLoaded.value = true;

        const routeQuery = route.query;
        if (
          route.name === 'home' &&
          routeQuery.startDay &&
          routeQuery.nrNights
        ) {
          router.push({ name: 'search', query: route.query });
        }
      }
    } catch (error) {
      console.error('Setup initialization failed:', error);
      setupLoaded.value = true;
      throw error;
    }
  };

  return {
    initializeApp,
    setupLoaded,
    informativeModalTitle,
    informativeModalDescription,
    informativeModalButtonText,
  };
}
