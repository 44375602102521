<template>
  <div v-if="setupLoaded" class="flex min-h-dvh flex-col pb-3">
    <Header />
    <main class="flex flex-grow md:justify-center">
      <router-view v-slot="{ Component }" class="md:max-w-[1440px]">
        <transition :name="transitionName" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
    <Footer class="px-4" />
  </div>

  <InformativeModal
    :isVisible="apiResponseError"
    :title="informativeModalTitle"
    :description="informativeModalDescription"
    :buttonText="informativeModalButtonText"
    :icon="reloadIcon"
    :showBell="true"
    type="danger"
    @update:isVisible="redirectToHome" />
</template>

<script setup>
import { onMounted, provide, ref, defineAsyncComponent, watch } from 'vue';
import { RouterView, useRouter, useRoute } from 'vue-router';
import router, { trackInitialPageView } from '@/router';
import { storeToRefs } from 'pinia';

import { useAppConfigStore } from '@stores/appConfig.store';
import { useGlobalNavigation } from '@/composables/useNavigation';
import { formatDate } from '@composables/useFormattedDate';
import { useAppInitialization } from '@/composables/useAppInitialization';
import { useGeolocation } from '@/composables/useGeolocation';
import Header from '@/components/layout/AppHeader.vue';
import Footer from '@/components/layout/AppFooter.vue';

const InformativeModal = defineAsyncComponent(
  () => import('@/components/customComponents/InformativeModal.vue'),
);

import useHttp from '@/utils/httpClient';

const reloadIcon = defineAsyncComponent(() => import('@icons/replay.svg'));

const http = useHttp();
const { apiResponseError } = storeToRefs(http);

const appConfigStore = useAppConfigStore();
const route = useRoute();

const transitionName = ref('slide-left');
let navigationStack = [];
let isNavigatingBack = false;
const navigationRouter = useRouter();
navigationRouter.beforeEach((to, from, next) => {
  const fromPath = from.path;
  const toPath = to.path;

  if (
    navigationStack.length > 0 &&
    navigationStack[navigationStack.length - 1] === toPath
  ) {
    isNavigatingBack = true;
    navigationStack.pop();
  } else {
    isNavigatingBack = false;
    if (fromPath) navigationStack.push(fromPath);
  }
  transitionName.value = isNavigatingBack ? 'slide-left' : 'slide-right';

  next();
});

const { formatCurrency, setCurrency } = appConfigStore;
const { setupLoaded, applicationSetup, setupCurrency } =
  storeToRefs(appConfigStore);

const { detectUserLocationAndCurrency, userCountryCode, userCurrency } =
  useGeolocation();

const { goBack } = useGlobalNavigation();
provide('goBack', goBack);
provide('formatCurrency', formatCurrency);
provide('applicationSetup', applicationSetup);
provide('formatDate', formatDate);
provide('userCountryCode', userCountryCode);
provide('userCurrency', userCurrency);

const {
  initializeApp,
  informativeModalTitle,
  informativeModalDescription,
  informativeModalButtonText,
} = useAppInitialization();

// Watch for changes to applicationSetup to track the initial page view
watch(
  () => applicationSetup.value?.hotel_id,
  (hotelId) => {
    if (hotelId) {
      // Track the initial page view once hotel_id is available
      trackInitialPageView(hotelId);
    }
  },
  { immediate: true },
);

onMounted(() => {
  Promise.all([initializeApp(), detectUserLocationAndCurrency()])
    .then(() => {
      const currency = sessionStorage.getItem(
        'currency' + applicationSetup.value.hotel_id,
      );

      if (currency) {
        setCurrency(currency);
        return;
      }

      const userCurrencyIsValid = applicationSetup.value.currencies?.some(
        (currency) => currency.currency === userCurrency.value,
      );

      if (userCurrencyIsValid && userCurrency.value) {
        setCurrency(userCurrency.value);
      } else {
        setCurrency(setupCurrency.value);
      }
    })
    .catch((error) => {
      console.error('Failed to initialize app:', error);
    });
});

const redirectToHome = () => {
  const filteredQuery = {
    [import.meta.env.VITE_APIKEY_PARAM_NAME]:
      route.query[import.meta.env.VITE_APIKEY_PARAM_NAME],
    channelKey: route.query.channelKey,
    language: route.query.language,
    hotel_id: route.query.hotel_id,
    referrer: route.query.referrer,
    url: route.query.url,
  };
  router
    .push({
      name: 'home',
      query: filteredQuery,
    })
    .then(() => {
      window.location.reload();
    });
  apiResponseError.value = false;
};
</script>
<style scoped>
main > div {
  transition-property: margin;
  transition-duration: 1s;
}

.slide-right-enter-active,
.slide-left-enter-active {
  transition:
    transform 0.5s ease-in,
    opacity 0.5s ease-in;
}
.slide-right-leave-active,
.slide-left-leave-active {
  transition:
    transform 0.5s ease-out,
    opacity 0.5s ease-out;
}
.slide-right-enter-from,
.slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-right-leave-to,
.slide-left-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
