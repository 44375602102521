import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { addDays, isValid, differenceInDays, format } from 'date-fns';
import useHttp from '@/utils/httpClient';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useAppConfigStore } from '@stores/appConfig.store';

const http = useHttp();

export const useBookingQueryStore = defineStore('bookingQuery', () => {
  const appConfigStore = useAppConfigStore();
  const { applicationSetup } = storeToRefs(appConfigStore);
  const route = useRoute();
  const occupancyRoomSettings = ref({});

  const activatedPromotionalCodes = ref([]);

  const userDirectIncentive = ref('');

  const query = ref({
    startDate: '',
    endDate: '',
    occupancy: [],
    promotion_code: '',
    campaign_code: '',
    referrer: '',
    filterOffers: '',
  });

  const setPromotionalCodeOnQuery = () => {
    let promoCodes = [];
    let campaignCode = '';
    activatedPromotionalCodes.value.forEach((code) => {
      if (code.isCampaignCode) {
        campaignCode = code.value;
      } else {
        promoCodes.push(code.value);
      }
    });
    query.value.promotion_code = JSON.stringify(promoCodes);
    query.value.campaign_code = campaignCode;
  };

  const loadPromotionsFromSession = () => {
    const savedPromotionalCodes = sessionStorage.getItem(
      'activatedPromotionalCodes' + applicationSetup.value.hotel_id,
    );
    if (savedPromotionalCodes) {
      activatedPromotionalCodes.value = JSON.parse(savedPromotionalCodes);
    }
  };

  const saveToSession = () => {
    sessionStorage.setItem(
      'activatedPromotionalCodes' + applicationSetup.value.hotel_id,
      JSON.stringify(activatedPromotionalCodes.value),
    );
  };

  const setInitialQuery = (setup) => {
    loadPromotionsFromSession();
    let defaultCheckinDate = new Date();
    if (
      setup.default_checkin_date &&
      setup.default_checkin_date != '0000-00-00'
    ) {
      defaultCheckinDate = new Date(setup.default_checkin_date + 'T00:00:00');
    }
    const endDate = addDays(
      defaultCheckinDate,
      parseInt(setup.default_nr_nights),
    );

    let defaultNrRooms = 1;
    if (setup.rooms) {
      defaultNrRooms = parseInt(setup.rooms);
    }
    let defaultNrAdults = 2;
    if (setup.occupancy.default_adults) {
      defaultNrAdults = parseInt(setup.occupancy.default_adults);
    }

    let occupancy = [];
    for (let i = 0; i < defaultNrRooms; i++) {
      occupancy.push({
        nrAdults: defaultNrAdults,
        nrChildren: 0,
        nrBabies: 0,
      });
    }

    let newQuery = {
      startDate: defaultCheckinDate,
      endDate: endDate,
      occupancy: occupancy,
      referrer: document.referrer,
    };

    newQuery = getQueryParams(newQuery, setup);
    query.value = newQuery;
    setPromotionalCodeOnQuery();
    occupancyRoomSettings.value = setup.occupancy;
  };

  const setStartDate = (newStartDate) => {
    query.value.startDate = newStartDate;
  };

  const setEndDate = (newEndDate) => {
    query.value.endDate = newEndDate;
  };

  const updateOccupancy = (occupancy) => {
    query.value.occupancy = occupancy;
  };

  const getQueryParams = (query, setup) => {
    const clonedRoute = { ...route.query };
    delete clonedRoute.promotion;
    delete clonedRoute.room;
    delete clonedRoute.preselect;
    delete clonedRoute.select;

    if (
      (route.query.promotion || route.query.preselect || route.query.select) &&
      !route.path.includes('/rate/details/')
    ) {
      let rateId = '';
      if (route.query.promotion && route.query.promotion.includes('promo')) {
        const promotionID = route.query.promotion.replace('promo', '');
        rateId = promotionID;
        if (route.query.room) {
          const roomID = route.query.room.replace('room', '');
          rateId = promotionID + '.' + roomID;
        }
      }

      const offerFormat = /^p\d+r\d+$/;
      if (
        route.query.preselect &&
        (offerFormat.test(route.query.preselect) ||
          route.query.preselect.includes('promo'))
      ) {
        if (route.query.preselect.includes('promo')) {
          rateId = route.query.preselect.replace('promo', '');
        }
        if (offerFormat.test(route.query.preselect)) {
          let codes = route.query.preselect.split('r');
          const promotionID = codes[0].replace('p', '');
          rateId = promotionID + '.' + codes[1];
        }
      }

      if (route.query.select && offerFormat.test(route.query.select)) {
        let codes = route.query.select.split('r');
        const promotionID = codes[0].replace('p', '');
        rateId = promotionID + '.' + codes[1];
      }

      if (rateId) {
        router.push({
          name: 'rate details',
          params: { id: rateId },
          query: clonedRoute,
        });
      }
    }

    if (
      route.query.room &&
      route.query.room.includes('room') &&
      !(route.query.promotion || route.query.preselect || route.query.select) &&
      !route.path.includes('/room/details/')
    ) {
      const roomID = route.query.room.replace('room', '');
      router.push({
        name: 'room details',
        params: { id: roomID },
        query: clonedRoute,
      });
    }

    if (route.query.startDay) {
      const dateParts = route.query.startDay.split('-');

      let queryDate;
      // Try ISO format (YYYY-MM-DD)
      if (dateParts[0].length === 4) {
        queryDate = new Date(
          dateParts[0], // year
          parseInt(dateParts[1]) - 1, // month (0-based)
          dateParts[2], // day
          '00',
          '00',
          '00',
        );
      }
      // Try European format (DD-MM-YYYY)
      else {
        queryDate = new Date(
          dateParts[2], // year
          parseInt(dateParts[1]) - 1, // month (0-based)
          dateParts[0], // day
          '00',
          '00',
          '00',
        );
      }

      if (isValid(queryDate)) {
        query.startDate = queryDate;

        const today = new Date();
        const formattedToday = format(today, 'yyyy-MM-dd');
        if (format(query.startDate, 'yyyy-MM-dd') < formattedToday) {
          query.startDate = new Date(formattedToday + 'T00:00:00');
        }
        let nrDays = setup.default_nr_nights;

        if (route.query.nrNights) {
          nrDays = parseInt(route.query.nrNights);
        }
        const endDate = addDays(query.startDate, nrDays);
        query.endDate = endDate;
      }
    }

    if (
      route.query.amount ||
      route.query.nrAdults ||
      route.query.nrChildren ||
      route.query.childrenAges ||
      route.query.nrBabies
    ) {
      const amount = route.query.amount
        ? parseInt(route.query.amount)
        : parseInt(setup.rooms);
      let nrAdults = route.query.nrAdults
        ? parseInt(route.query.nrAdults)
        : parseInt(setup.occupancy.default_adults);
      let occupancies = [];

      let childrenAges = route.query.childrenAges
        ? JSON.parse(route.query.childrenAges)
        : null;

      let nrChildren = route.query.nrChildren
        ? parseInt(route.query.nrChildren)
        : 0;

      if (nrChildren > setup.occupancy.max_children) {
        nrChildren = setup.occupancy.max_children;
      }

      if (nrChildren > 0 && !childrenAges) {
        childrenAges = Array(nrChildren).fill(
          setup.occupancy.min_children_ages,
        );
      }

      let nrBabies = route.query.nrBabies ? parseInt(route.query.nrBabies) : 0;

      if (childrenAges) {
        nrChildren = 0;
        nrBabies = 0;

        let adultsInChildrenAges = false;
        let nrAdultsInChildrenAges = 0;

        childrenAges.forEach((age) => {
          if (age > setup.occupancy.max_children_ages) {
            adultsInChildrenAges = true;
            nrAdultsInChildrenAges += 1;
          } else if (age < setup.occupancy.min_children_ages) {
            nrBabies += 1;
          } else {
            nrChildren += 1;
          }
        });

        if (adultsInChildrenAges && nrAdultsInChildrenAges > 0) {
          nrAdults = nrAdultsInChildrenAges;
        }
      }

      for (let i = 0; i < amount; i++) {
        occupancies.push({
          nrAdults: nrAdults,
          nrChildren: nrChildren,
          nrBabies: nrBabies,
          children_ages: childrenAges || [],
        });
      }
      query.occupancy = occupancies;
    }

    if (route.query.occupancies) {
      let occupancy = JSON.parse(route.query.occupancies);
      let occupancies = [];
      for (let i = 0; i < occupancy.length; i++) {
        occupancies.push({
          nrAdults: parseInt(occupancy[i].adults),
          nrChildren: parseInt(occupancy[i].children),
          nrBabies: parseInt(occupancy[i].babies),
          children_ages: occupancy[i].children_ages || [],
        });
      }
      query.occupancy = occupancies;
    }

    if (route.query.url) {
      query.referrer = route.query.url;
    }
    if (route.query.r) {
      query.referrer = route.query.r;
    }

    if (route.query.pc) {
      const existingCode = activatedPromotionalCodes.value.find(
        (code) => code.value === route.query.pc,
      );
      if (!existingCode) {
        checkCampaignCode(route.query.pc).then((response) => {
          if (
            response &&
            (response.isCampaignCodeCodeValid ||
              response.isPromoCodeCodeValid ||
              response.isSpecialRateCodeCodeValid)
          ) {
            activatedPromotionalCodes.value.push({
              value: route.query.pc,
              isCampaignCode: response.isCampaignCodeCodeValid || false,
            });
            saveToSession();
            setPromotionalCodeOnQuery();
          }
        });
      }
    }

    if (
      route.query.filterOffers &&
      (route.query.filterOffers === 'all' ||
        route.query.filterOffers === 'promotions')
    ) {
      query.filterOffers = route.query.filterOffers;
    }

    return query;
  };

  const userOccupancyTotals = computed(() => {
    return query.value.occupancy.reduce(
      (acc, curr) => {
        acc.totalRooms += 1;
        acc.totalAdults += curr.nrAdults;
        acc.totalChildren += curr.nrChildren;
        acc.totalBabies += curr.nrBabies;
        return acc;
      },
      { totalRooms: 0, totalAdults: 0, totalChildren: 0, totalBabies: 0 },
    );
  });

  const updateUserSpecialRatesDetails = (userCodes) => {
    activatedPromotionalCodes.value = userCodes;
    setPromotionalCodeOnQuery();
    saveToSession();
  };

  const checkCampaignCode = async (campaignCode) => {
    const params = {
      campaign_code: campaignCode,
    };

    return new Promise((resolve, reject) => {
      http
        .get('check_campaigncode', params)
        .then((rsp) => {
          const responseContext = rsp.data.response;
          resolve(responseContext);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const removePromoCode = (value) => {
    activatedPromotionalCodes.value = activatedPromotionalCodes.value.filter(
      (code) => code.value !== value,
    );
    setPromotionalCodeOnQuery();
    saveToSession();
  };

  const resetPromotionalCodes = () => {
    activatedPromotionalCodes.value = [];
    setPromotionalCodeOnQuery();
    saveToSession();
  };

  const daysDifference = computed(() => {
    if (
      !isValid(new Date(query.value.startDate)) ||
      !query.value.endDate ||
      !isValid(new Date(query.value.endDate))
    ) {
      return 0;
    }
    return differenceInDays(
      new Date(query.value.endDate),
      new Date(query.value.startDate),
    );
  });

  return {
    query,
    userOccupancyTotals,
    occupancyRoomSettings,
    userDirectIncentive,
    setInitialQuery,
    setStartDate,
    setEndDate,
    updateOccupancy,
    updateUserSpecialRatesDetails,
    checkCampaignCode,
    activatedPromotionalCodes,
    removePromoCode,
    daysDifference,
    loadPromotionsFromSession,
    resetPromotionalCodes,
  };
});
