import { ref } from 'vue';
export function useGeolocation() {
  const userCountryCode = ref(null);
  const userCurrency = ref(null);

  const detectUserLocationAndCurrency = async () => {
    try {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();

      if (data && data.country_code) {
        const countryCode = data.country_code.toLowerCase();
        if (countryCode) {
          userCountryCode.value = countryCode;
          const countryResponse = await fetch(
            `https://restcountries.com/v3.1/alpha/${countryCode}`,
          );
          const countryData = await countryResponse.json();
          const currency = Object.keys(countryData[0].currencies)[0] || '';
          userCurrency.value = currency;
        }
      }
    } catch (error) {
      console.log('Error detecting user location and currency:', error);
    }
  };

  return {
    detectUserLocationAndCurrency,
    userCountryCode,
    userCurrency,
  };
}
