<template>
  <CustomButton
    v-if="isLoyaltyEnabled && isMobile"
    :disabled="
      !isLoginEnabled ||
      (!applicationSetup.loyalty.status && !applicationSetup.login) ||
      paymentFailed
    "
    :label="
      applicationSetup.loyalty.status
        ? applicationSetup.loyalty.button_label
        : ''
    "
    @click="makeLoginVisible"
    class="mb-2 h-8 w-full rounded-none px-6"
    spanClass="gc-font-small-title" />
  <header
    class="sticky top-0 z-40 flex min-h-10 items-center justify-between bg-bg-header px-4 md:min-h-24 md:px-8">
    <div class="flex items-center">
      <div
        class="icon mr-3 flex w-10 cursor-pointer items-center justify-center rounded-full border-border-nav-icons border-burger-border bg-burger-bg px-1 py-2 hover:border-burger-border-hover hover:bg-burger-bg-hover"
        @click="toogleMenu">
        <div class="icon-line"></div>
        <div class="icon-line"></div>
        <div class="icon-line"></div>
      </div>
      <div class="mr-1 flex">
        <a
          @click="goHome"
          class="cursor-pointer"
          v-if="!isMobile || route.name !== 'home'">
          <img
            v-if="applicationSetup.hotel_logo_desktop"
            fetchpriority="high"
            width="270"
            height="40"
            :src="applicationSetup.hotel_logo_desktop"
            :alt="applicationSetup.hotel_name" />
          <span v-else>{{ applicationSetup.hotel_name }}</span>
        </a>
      </div>
    </div>
    <!-- <AppHeaderMenu v-if="!isMobile"></AppHeaderMenu> -->
    <div class="flex">
      <CustomButton
        v-if="isLoyaltyEnabled && !isMobile"
        :disabled="
          !isLoginEnabled ||
          (!applicationSetup.loyalty.status && !applicationSetup.login) ||
          paymentFailed
        "
        :label="
          applicationSetup.loyalty.status
            ? applicationSetup.loyalty.button_label
            : ''
        "
        @click="makeLoginVisible"
        class="mr-2 px-6"
        spanClass="gc-font-small-title"
        customClass="primary"
        height="h-8"
        width="w-auto" />
      <IconButton
        v-if="isLanguageSelectionEnabled"
        class="mr-2 border-border-nav-icons border-nav-icons-border bg-nav-icons-bg text-header-icons hover:border-nav-icons-border-hover hover:!bg-nav-icons-bg-hover hover:!text-header-icons-hover"
        ariaLabel="Language"
        :text="languageSelection ? languageSelection.toUpperCase() : ''"
        textClass="gc-font-main-text"
        @click="toggleLanguageDrawer"></IconButton>

      <IconButton
        v-if="isCurrencySelectionEnabled && applicationSetup.currency"
        :disabled="!isLoginEnabled || paymentFailed"
        class="border-border-nav-icons border-nav-icons-border bg-nav-icons-bg text-header-icons hover:border-nav-icons-border-hover hover:!bg-nav-icons-bg-hover hover:!text-header-icons-hover"
        ariaLabel="Currency"
        :text="currencySelection ? currencySelection.toUpperCase() : ''"
        textClass="gc-font-main-text"
        @click="toggleCurrencyDrawer"></IconButton>
    </div>
    <CustomDrawer
      v-if="isLanguageSelectionEnabled"
      v-model="isLanguageDrawerOpen"
      :position="isMobile ? 'bottom' : 'right'"
      :width="isMobile ? 'w-full' : 'w-[35%]'"
      height="h-auto">
      <template #drawerContent>
        <!-- Content inside the currency drawer -->
        <CustomLanguageDrawer @confirm="changeLanguage" />
      </template>
    </CustomDrawer>

    <CustomDrawer
      v-if="isCurrencySelectionEnabled"
      v-model="isCurrencyDrawerOpen"
      :position="isMobile ? 'bottom' : 'right'"
      :width="isMobile ? 'w-full' : 'w-[35%]'"
      height="h-auto">
      <template #drawerContent>
        <CustomCurrencyDrawer @confirm="changeCurrency" />
      </template>
    </CustomDrawer>

    <CustomDrawer
      v-model="drawerOpen"
      position="left"
      :width="isMobile ? 'w-full' : 'w-[35%]'"
      height="h-auto"
      drawerContentClass="h-full flex flex-grow bg-secondary relative">
      <template #drawerContent>
        <AppMenu
          @close="drawerOpen = false"
          @showCookies="cookiesDrawerModel = !cookiesDrawerModel"></AppMenu>
      </template>
    </CustomDrawer>
    <CustomDrawer
      v-model="isLoginVisible"
      :position="isMobile ? 'bottom' : 'right'"
      :width="isMobile ? 'w-full' : 'w-[35%]'"
      height="h-auto"
      @update:modelValue="onDrawerClose">
      <template #drawerContent>
        <Login
          @close="closeLoginDrawer"
          @showLoggedInModal="onShowLoggedInModal"
          @showLoggedOutModal="onShowLoggedOutModal"
          @showSignupModal="onShowSignUpModal"
          @isSignInEmptyOrValid="handleSignInValidation"
          @isSignUpEmptyOrValid="handleSignUpValidation"></Login>
      </template>
    </CustomDrawer>

    <CustomDrawer
      v-if="$t('privatePoliciesTitle')"
      v-model="cookiesDrawerModel"
      :position="isMobile ? 'bottom' : 'right'"
      :width="isMobile ? 'w-full' : 'w-[35%]'"
      height="h-auto"
      @update:modelValue="onCookiesDrawerClose">
      <template #drawerContent>
        <CookiesMain @closeModal="closeCookiesModal" />
      </template>
    </CustomDrawer>

    <InformativeModal
      :isVisible="showLoggedInModal"
      :title="$t('general.login')"
      :description="$t('general.loginSuccess')"
      :buttonText="$t('general.closeButtonText')"
      type="success"
      @update:isVisible="showLoggedInModal = false" />
    <InformativeModal
      :isVisible="showLoggedOutModal"
      :title="$t('general.logout')"
      :description="$t('general.logoutSuccess')"
      :buttonText="$t('general.closeButtonText')"
      type="success"
      @update:isVisible="showLoggedOutModal = false" />
    <InformativeModal
      :isVisible="showSignUpModal"
      :title="$t('general.emailSentTitle')"
      :description="$t('general.emailSentDescription')"
      :buttonText="$t('general.closeButtonText')"
      type="success"
      @update:isVisible="showSignUpModal = false" />
  </header>
</template>

<script setup>
import { useRoute } from 'vue-router';
const route = useRoute();

import { ref, computed, defineAsyncComponent, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import CookiesMain from '@/components/Cookies/CookiesMain.vue';

const AppMenu = defineAsyncComponent(() => import('./AppMenu.vue'));
const IconButton = defineAsyncComponent(
  () => import('@/components/customComponents/IconButton.vue'),
);
const CustomLanguageDrawer = defineAsyncComponent(
  () => import('@/components/Language/CustomLanguageDrawer.vue'),
);
const CustomCurrencyDrawer = defineAsyncComponent(
  () => import('@/components/Currency/CustomCurrencyDrawer.vue'),
);
import { useScreenSize } from '@composables/useScreenSize';
import { setLocale } from '@/locales/i18n';
import { useAppConfigStore } from '@stores/appConfig.store';
import CustomDrawer from '../customComponents/CustomDrawer.vue';
import { useTranslationCache } from '@stores/translationCache.store';
import Login from '@/components/Login/LoginMain.vue';
import { useUserProfile } from '@stores/userProfile.store';
import { useMenus } from '@stores/menus.store';
// import AppHeaderMenu from './AppHeaderMenu.vue';
import router from '@/router';
import InformativeModal from '@/components/customComponents/InformativeModal.vue';
import CustomButton from '@/components/customComponents/CustomButton.vue';
import { useBookingQueryStore } from '@stores/bookingQuery.store.js';
const bookingQueryStore = useBookingQueryStore();
const { setInitialQuery } = bookingQueryStore;

const cookiesDrawerModel = ref(false);

onMounted(() => {
  if (!sessionStorage.getItem('userCookies')) {
    cookiesDrawerModel.value = true;
  }
});

const onCookiesDrawerClose = () => {
  if (sessionStorage.getItem('userCookies')) {
    cookiesDrawerModel.value = false;
  } else {
    cookiesDrawerModel.value = true;
  }
};

const drawerOpen = ref(false);
const isLoginVisible = ref(false);
const isLanguageDrawerOpen = ref(false);
const isCurrencyDrawerOpen = ref(false);
const showLoggedInModal = ref(false);
const showLoggedOutModal = ref(false);
const showSignUpModal = ref(false);

const isSignInEmptyOrValid = ref(true);
const handleSignInValidation = (value) => {
  isSignInEmptyOrValid.value = value;
};

const isSignUpEmptyOrValid = ref(true);
const handleSignUpValidation = (value) => {
  isSignUpEmptyOrValid.value = value;
};

const appConfigStore = useAppConfigStore();
const { setLanguage, setCurrency } = appConfigStore;
const { applicationSetup } = storeToRefs(appConfigStore);
const translationCache = useTranslationCache();
const userProfileStore = useUserProfile();
const { userProfile } = storeToRefs(userProfileStore);
const menusStore = useMenus();
const { getMenu } = menusStore;

const { isMobile } = useScreenSize();

const languageSelection = computed(() => applicationSetup.value.language);
const currencySelection = computed(() => applicationSetup.value.currency);

const isLanguageSelectionEnabled = computed(() => {
  return (
    Array.isArray(applicationSetup.value.languages) &&
    applicationSetup.value.languages.length > 1
  );
});
const isCurrencySelectionEnabled = computed(() => {
  return (
    Array.isArray(applicationSetup.value.currencies) &&
    applicationSetup.value.currencies.length > 1
  );
});
const isLoyaltyEnabled = computed(() => {
  return (
    applicationSetup.value &&
    applicationSetup.value.loyalty &&
    applicationSetup.value.loyalty.status === true
  );
});

const isLoginEnabled = computed(() => {
  if (
    router.currentRoute.value.path === '/payment' ||
    router.currentRoute.value.path === '/confirmation'
  ) {
    return false;
  }
  return true;
});

onMounted(() => {
  if (route.query.lt && route.query.ltl && !userProfile.value.isAuthenticated) {
    isLoginVisible.value = true;
  }
});

const paymentFailed = computed(() => {
  return parseInt(route.query.paymentFailed) ? true : false;
});

const onShowLoggedInModal = () => {
  showLoggedInModal.value = true;
  closeLoginDrawer();
};

const onShowLoggedOutModal = () => {
  showLoggedOutModal.value = true;
  closeLoginDrawer();
};

const onShowSignUpModal = () => {
  showSignUpModal.value = true;
  closeLoginDrawer();
};

const changeLanguage = async (language) => {
  await setLocale(language);
  translationCache.fetchAndUpdateTranslations();
  setLanguage(language);
  getMenu();

  isLanguageDrawerOpen.value = false;
};

const changeCurrency = (currency) => {
  setCurrency(currency);
  isCurrencyDrawerOpen.value = false;
};

const closeLoginDrawer = () => {
  isLoginVisible.value = false;
};

const makeLoginVisible = () => {
  isLoginVisible.value = true;
};

const toogleMenu = () => {
  drawerOpen.value = !drawerOpen.value;
};

const toggleLanguageDrawer = () => {
  isLanguageDrawerOpen.value = !isLanguageDrawerOpen.value;
};

const toggleCurrencyDrawer = () => {
  isCurrencyDrawerOpen.value = !isCurrencyDrawerOpen.value;
};

const closeCookiesModal = () => {
  cookiesDrawerModel.value = false;
};

const goHome = async () => {
  if (route.name !== 'home') {
    bookingQueryStore.resetPromotionalCodes();

    const { useReservationStore } = await import('@/stores/reservation.store');
    const reservationStore = useReservationStore();
    const { resetReservation } = reservationStore;
    resetReservation();

    const query = { ...route.query };
    if (query.startDay) {
      delete query.startDay;
    }
    if (query.nrNights) {
      delete query.nrNights;
    }

    await router.push({
      name: 'home',
      query,
      replace: true,
    });

    setInitialQuery(applicationSetup.value);
  }
};

const onDrawerClose = () => {
  const isSignUpVisible = document.querySelector('.signUpButton');
  const isSignUp = !!isSignUpVisible;

  const isValid = isSignUp
    ? isSignUpEmptyOrValid.value
    : isSignInEmptyOrValid.value;
  const elementToCheck = isSignUp ? '.signUpButton' : '.signInButton';

  if (!isValid) {
    document.querySelector(elementToCheck).click();
    isLoginVisible.value = true;
  } else {
    isLoginVisible.value = false;
  }
};
</script>

<style scoped>
.logged-in {
  @apply bg-header-icons text-light;
}

.icon {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
}
.icon-line {
  @apply ml-1;
  height: var(--border-burger);
  margin-bottom: var(--spacer-burger);
  background-color: var(--color-burger);
  border-radius: var(--border-burger);
  width: 75%;
  align-self: flex-start;
}
.icon:hover .icon-line {
  background-color: var(--color-burger-hover);
}

.icon-line:nth-child(3) {
  width: 50%; /* Shorter width for the last line */
  align-self: flex-start;
}
</style>
