import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import countriesList from '@/locales/countries.json';

export const useAppConfigStore = defineStore('appConfig', () => {
  const setupLoaded = ref(false);
  const applicationSetup = ref({});
  const countries = countriesList;
  const setupCurrency = ref('');
  const { n } = useI18n();

  const setCountries = () => {
    applicationSetup.value.countries = countries;
  };

  const setLanguage = (newLanguage) => {
    applicationSetup.value.language = newLanguage;
    sessionStorage.setItem(
      'language' + applicationSetup.value.hotel_id,
      newLanguage,
    );
  };

  const setCurrency = (newCurrency) => {
    applicationSetup.value.currency = newCurrency;
    applicationSetup.value.currencySymbol =
      applicationSetup.value.currencies.find(
        (item) => item.currency === newCurrency,
      ).symbol;
    sessionStorage.setItem(
      'currency' + applicationSetup.value.hotel_id,
      newCurrency,
    );
  };

  const setApplicationSetup = async (setupData) => {
    if (!setupData.hotel_id) {
      console.error('Warning: hotel_id is missing in setup data', setupData);
    }

    setupCurrency.value = setupData.currency;
    delete setupData.currency;

    applicationSetup.value = setupData;
    setCountries();
  };

  const formatCurrency = (value, decimals = 0) => {
    if (decimals === 0) {
      value = value < 0 ? Math.ceil(value) : Math.floor(value);
    }

    if (!applicationSetup.value.currency) {
      return;
    }

    return n(value, {
      style: 'currency',
      currency: applicationSetup.value.currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  };

  return {
    setupLoaded,
    setupCurrency,
    applicationSetup,
    setApplicationSetup,
    setLanguage,
    setCurrency,
    formatCurrency,
  };
});
