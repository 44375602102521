import { createI18n } from 'vue-i18n';
import { ref } from 'vue';
const firstLoad = ref(true);
const loadedLocales = new Set();

async function loadLocaleMessages(locale) {
  let commonLocale = null;
  let commonJson = {};
  try {
    commonLocale = await fetch(/* @vite-ignore */ `/locales/${locale}.json`);
    commonJson = await commonLocale.json();
  } catch (error) {
    console.warn('missing common locale', locale);
    commonLocale = await fetch(
      /* @vite-ignore */ `/locales/${i18n.global.fallbackLocale.value}.json`,
    );
    commonJson = await commonLocale.json();
  }
  let amenitiesLocale = await fetch(
    /* @vite-ignore */ `/locales/amenities/${locale}.json`,
  );

  let generalLocale = await fetch(
    /* @vite-ignore */ `/locales/general/${locale}.json`,
  );

  let amenitiesJson = await amenitiesLocale.json();
  let general = await generalLocale.json();
  return {
    ...commonJson,
    amenities: amenitiesJson,
    general: general,
  };
}

const i18n = createI18n({
  locale: 'pt',
  fallbackLocale: 'en',
  legacy: false,
  messages: {},
  datetimeFormats: {},
});

async function setLocale(locale) {
  if (loadedLocales.has(locale)) {
    i18n.global.locale.value = locale;
    return;
  }

  if (firstLoad.value && i18n.global.fallbackLocale.value !== locale) {
    const fallbackMessages = await loadLocaleMessages(
      i18n.global.fallbackLocale.value,
    );
    i18n.global.setLocaleMessage(
      i18n.global.fallbackLocale.value,
      fallbackMessages,
    );
    loadedLocales.add(i18n.global.fallbackLocale.value);
  }
  firstLoad.value = false;

  try {
    const messages = await loadLocaleMessages(locale);
    i18n.global.setLocaleMessage(locale, messages);
    i18n.global.locale.value = locale;
    loadedLocales.add(locale);
  } catch (error) {
    console.error('missing locale', locale);
  }
}

export { i18n, setLocale };
